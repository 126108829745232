import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AppBarComponent,
  CardDescription,
  CardTrailComponent,
  ContainerComponent,
  DividerComponent,
  ModalBNCCComponent,
  ValidateDataSessionComponent,
} from '~/components'
import CardDetailTrail from '~/components/Cards/CardDetailTrail/CardDetailTrail'
import StageSteps from '~/components/Navigation/StageSteps/StageSteps'
import TruncatedTextDescription from '~/components/ui/TruncatedTextDescription/TruncatedTextDescription'

import CardCarousel from '~/containers/private/Fund1/Planning/Carrousell/CardCarousel'
import { HeaderComponent } from '~/containers/private/Trails/TrailDetailsClass'

import { useTrailDetailsClassPageContext } from './context'
import * as S from './styles'

export const TrailDetailsClassView = () => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const { classId, trailId } = useParams()
  const {
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    handleSchedule,
    isLoadingTrailsDetails,
    dataTrailsClass,
    coStage,
    txGrade,
    txClassShift,
    lesson,
    track,
    txSchoolGradeName,
    setOpenDetail,
    setLesson,
    dataDetail,
    handleBack,
    pathOrientation,
  } = useTrailDetailsClassPageContext()

  const idTrackLesson = track?.idTrack
  const [trailIndex, setTrailIndex] = useState<number | null>(null)
  const sortedLessons = track?.lesson?.sort((a, b) => a.nuOrder - b.nuOrder) || []
  const [activeCardsStep, setActiveCardsStep] = useState(true)
  const [activeStep, setActiveStep] = useState(false)
  const [activeStepTwo, setActiveStepTwo] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentTrailIndex, setCurrentTrailIndex] = useState(trailIndex)
  const itemsPerPage = track?.lesson?.length <= 4 ? 4 : 3
  const navigate = useNavigate()

  const totalCards = track?.lesson?.length || 0
  const maxInitialIndex = totalCards - itemsPerPage
  const isPrevDisabled = currentIndex === 0
  const isNextDisabled = currentIndex >= totalCards - itemsPerPage
  const adjustedCurrentIndex = Math.min(currentIndex, maxInitialIndex)

  const handleNavigate = () => {
    localStorage.setItem('@LEKTO:Navigation', location.pathname)
    navigate(pathOrientation)
  }
  const handleActiveCard = (idLesson: number) => {
    const index = sortedLessons.findIndex((l) => l.idLesson === idLesson)

    if (index !== -1) {
      setTrailIndex(index)
      setLesson(sortedLessons[index])
      setActiveStep(true)
      setActiveCardsStep(false)
      setActiveStepTwo(false)
      if (lesson) {
        handleBack()
      }
    }
  }

  const handleActiveCardByIndex = (index: number) => {
    setTrailIndex(index)
    setLesson(sortedLessons[index])
    setActiveStep(true)
    setActiveCardsStep(false)
    setActiveStepTwo(false)
    if (lesson) {
      handleBack()
    }
  }
  function handleAllLessons() {
    setTrailIndex(null)
    handleBack()
    setActiveCardsStep(true)
    setActiveStep(false)
    setActiveStepTwo(false)
  }

  const handlePrevClick = (allTheClasses = false) => {
    if (lesson) {
      if (lesson.nuOrder > 1) {
        setLesson(sortedLessons[lesson.nuOrder - 2])
      }
    } else {
      if (allTheClasses && currentIndex > 0) {
        setCurrentIndex(currentIndex - 3)
      } else {
        if ((trailIndex ?? 0) > 0) {
          handleSelect((trailIndex ?? 0) - 1)
        }
      }
    }
    setOpenDetail(false)
  }

  const handleNextClick = (allTheClasses = false) => {
    if (lesson) {
      if (lesson.nuOrder < totalCards) {
        setLesson(sortedLessons[lesson.nuOrder - 2])
      }
    } else {
      if (allTheClasses && currentIndex < totalCards - itemsPerPage) {
        setCurrentIndex(currentIndex + 3)
      } else {
        if (!((trailIndex ?? 0) + 1 === totalCards)) {
          handleSelect((trailIndex ?? 0) + 1)
        }
      }
    }
    setOpenDetail(false)
  }
  const handleSelect = (index: number) => {
    handleActiveCardByIndex(index)
    setOpenDetail(false)
  }

  const returnVariant = (type: string) => {
    switch (type) {
      case 'one':
        return 'firstStep'
      case 'two':
        return 'secondStep'
      case 'three':
        return 'thirdStep'
      default:
        return 'default'
    }
  }

  useEffect(() => {
    setCurrentTrailIndex(trailIndex)
  }, [trailIndex])

  return (
    <S.ContainerScroll>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          loading={isLoadingTrailsDetails}
          path={`/trails-available/${classId}`}
          position={idTrackLesson}
          title={track?.txTitle as string}
          components={track?.component}
          description={track?.txDescription ? track?.txDescription : 'Sem descrição de trilha.'}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionSelectTrail={handleSchedule}
          coStage={coStage}
          txGrade={txGrade}
          txClassShift={txClassShift}
          txSchoolGradeName={txSchoolGradeName}
          idClass={classId}
        />
        {isLoadingTrailsDetails && !(dataTrailsClass?.length ?? 0) ? (
          <ValidateDataSessionComponent
            screen="home"
            title="Você não tem nenhuma trilha disponível."
            description="Entre em contato com a coordenação da sua escola ou com o suporte Lekto para mais informações."
          />
        ) : (
          <S.ContentList component="ul">
            {isLoadingTrailsDetails ? (
              <>
                {Object.keys(new Array(5).fill(null)).map((_, index: number) => (
                  <CardTrailComponent loading={isLoadingTrailsDetails} key={index} index={index} data={null} />
                ))}
              </>
            ) : (
              <>
                {!(activeStep || activeStepTwo) && <CardDescription description={track?.txDescription} />}
                {!activeStep && (
                  <StageSteps
                    data={track}
                    onChangeTrailIndex={trailIndex}
                    handleAllLessons={handleAllLessons}
                    handlePrevClick={() => handlePrevClick(true)}
                    handleNextClick={() => handleNextClick(true)}
                    isPrevDisabled={isPrevDisabled}
                    isNextDisabled={isNextDisabled}
                    adjustedCurrentIndex={adjustedCurrentIndex}
                    currentIndex={currentIndex}
                    itemsPerPage={itemsPerPage}
                    handleSelect={handleSelect}
                  />
                )}
                {activeCardsStep && (
                  <S.CardSliderResumeContainer isMobile={isMobile}>
                    {track?.lesson?.length < 4
                      ? sortedLessons.map((lesson, index) => (
                          <div key={index}>
                            <CardDetailTrail
                              index={index}
                              dataTrail={lesson}
                              handleActiveCard={() => handleActiveCard(lesson.idLesson)}
                              variant="P1"
                              itemsPerPage={sortedLessons.length}
                            />
                          </div>
                        ))
                      : sortedLessons
                          .slice(adjustedCurrentIndex, adjustedCurrentIndex + itemsPerPage)
                          .map((lesson, index) => (
                            <div key={index}>
                              <CardDetailTrail
                                index={index}
                                dataTrail={lesson}
                                handleActiveCard={() => handleActiveCard(lesson.idLesson)}
                                variant="P1"
                                itemsPerPage={sortedLessons.length}
                              />
                            </div>
                          ))}
                  </S.CardSliderResumeContainer>
                )}
                {!lesson && activeStep && (
                  <>
                    <StageSteps
                      data={track}
                      onChangeTrailIndex={trailIndex ?? 0}
                      handleAllLessons={handleAllLessons}
                      handlePrevClick={() => handlePrevClick(false)}
                      handleNextClick={() => handleNextClick(false)}
                      isPrevDisabled={(trailIndex ?? 0) + 1 ? (trailIndex ?? 0) + 1 === 1 : isPrevDisabled}
                      isNextDisabled={(trailIndex ?? 0) + 1 ? (trailIndex ?? 0) + 1 === totalCards : isNextDisabled}
                      currentIndex={(trailIndex ?? 0) + 1}
                      handleSelect={handleSelect}
                      hasLesson={true}
                      itemsPerPage={1}
                    />

                    <S.StepHeader>
                      <S.ContentNumber>
                        <S.StepNumber>{trailIndex != null ? trailIndex + 1 : ''}</S.StepNumber>
                      </S.ContentNumber>
                      <S.TitleLessonStep>{trailIndex !== null && sortedLessons[trailIndex].txTitle}</S.TitleLessonStep>
                    </S.StepHeader>
                    <S.CardDescription>
                      <TruncatedTextDescription
                        title={'aula'}
                        txDescription={trailIndex !== null && sortedLessons[trailIndex].txDescription}
                        isMobile={false}
                        textLength={470}
                      />
                      {dataDetail?.lessonActivity[0].activityOrientations?.map((info, index) => (
                        <S.AccordionContainer key={index}>
                          <S.AccordionSummaryContent
                            onClick={() => handleNavigate()}
                            data-testid={'exploreClassDirections'}
                            expandIcon={
                              <S.CircleIcon>
                                <UilArrowRight />
                              </S.CircleIcon>
                            }
                          >
                            <S.OrientationTitle>{'Orientações da Aula'}</S.OrientationTitle>
                          </S.AccordionSummaryContent>

                          <S.AccordionDetailsContent>
                            <S.ContentParagraphs>
                              <S.Paragraph>
                                <S.TextParagraph>{parse(String(info?.txOrientationCode))}</S.TextParagraph>
                              </S.Paragraph>
                            </S.ContentParagraphs>

                            {info?.supportReferences && info?.supportReferences?.length > 0 && (
                              <>
                                <DividerComponent />
                                <S.Title variant="caption" type={'one'}>
                                  Referências de Apoio
                                </S.Title>
                                <S.ContentCards>
                                  <CardCarousel
                                    key={index}
                                    items={info?.supportReferences}
                                    total={3}
                                    type={returnVariant('one')}
                                  />
                                </S.ContentCards>
                              </>
                            )}
                          </S.AccordionDetailsContent>
                        </S.AccordionContainer>
                      ))}
                    </S.CardDescription>
                  </>
                )}
              </>
            )}
          </S.ContentList>
        )}
      </ContainerComponent>

      <ModalBNCCComponent
        open={openModalBNCC}
        txGuidanceBNCC={track?.txGuidanceBNCC}
        actionCloseModal={handleCloseModalBNCC}
      />
    </S.ContainerScroll>
  )
}
