import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight, UilCalender } from '@iconscout/react-unicons'
import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import { useTrackSchedule } from '~/services/Track'
import { useAvailableClassesToSchedule } from '~/services/TrailSchedule'
import { IAvailableClassesToSchedule } from '~/services/TrailSchedule/Trail.interfaces'

import { ButtonComponent, SkeletonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'
import { ModalComponentBase } from '~/components/Modals/ModalBase/Modal'
import { IModalClassesAvailableToScheduleProps } from '~/components/Modals/ModalClassesAvailableToSchedule/ModalClassesAvailableToSchedule.interface'

import { translateCoStage } from '~/validations/translateCoStage'

import * as S from './ModalClassesAvailableToSchedule.styles'

export function ModalClassesAvailableToSchedule({
  openAvailableClassesToScheduleModal,
  closeToAvailableClasses,
}: IModalClassesAvailableToScheduleProps) {
  const { classId, trackId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {
    data: dataTrackSchedule,
    isLoading: loadingGet,
    refetch,
    remove,
  } = useTrackSchedule(Number(classId), Number(trackId), !!classId && !!trackId)
  const classInfo = dataTrackSchedule?.class
  const idTrack = dataTrackSchedule?.idTrack
  const titleTrack = dataTrackSchedule?.txTrackTitle

  const {
    data: dataAvailableClassesToSchedule,
    remove: removeAvailableClassesToSchedule,
    isLoading: loadingAvailableClassesToSchedule,
    error: errorAvailableClassesToSchedule,
  } = useAvailableClassesToSchedule(Number(trackId), String(classInfo?.coGrade), openAvailableClassesToScheduleModal)

  const handleCloseAvailableClassesToScheduleModal = () => {
    sessionStorage.setItem('@LEKTO:actionAvailableToSchedule', `false`)
  }

  const handleNavigateToSchedule = (idClass: number) => {
    sessionStorage.setItem('@LEKTO:actionAvailableToSchedule', `false`)
    removeAvailableClassesToSchedule()
    handleCloseAvailableClassesToScheduleModal()
    queryClient.invalidateQueries(['EngageTrail']).then()
    navigate(`/schedule/${idClass}/track/${idTrack}`)
    toast.remove()
  }

  const actionScheduleLater = () => {
    sessionStorage.setItem('@LEKTO:actionAvailableToSchedule', `false`)
    removeAvailableClassesToSchedule()
    handleCloseAvailableClassesToScheduleModal()
    closeToAvailableClasses()
  }

  return (
    <ModalComponentBase
      width={'fit-content'}
      maxWidth={'524px'}
      open={openAvailableClassesToScheduleModal}
      actionCloseModal={handleCloseAvailableClassesToScheduleModal}
    >
      <S.ModalClassesAvailableContainer>
        <S.ModalTitle>
          Esta trilha também pode ser escolhida para outras turmas. Aproveite e já faça o agendamento. É prático e
          rápido!
        </S.ModalTitle>

        <S.TitleContainer>
          <S.InfoTrail>Trilha</S.InfoTrail>
          <S.TitleTrail>{titleTrack}</S.TitleTrail>
        </S.TitleContainer>

        <S.ContentContainer className="styled-scroll-s">
          {loadingAvailableClassesToSchedule ? (
            <Box display={'flex'} alignItems={'center'} gap={'16px'}>
              <SkeletonComponent variant="text" width={20} height={32} />
              <SkeletonComponent variant="text" width={200} height={32} />
            </Box>
          ) : (
            <>
              {dataAvailableClassesToSchedule &&
                dataAvailableClassesToSchedule.map((item: IAvailableClassesToSchedule) => (
                  <S.ClassesCardContainer key={item.idClass}>
                    <S.ClassesTitleSection>
                      <span>
                        <strong>{item.txGrade}</strong> - {item.txClassName}
                      </span>
                      <TagClass
                        texts={[translateCoStage(item.coStage) ?? '', item.txClassShift ?? '']}
                        variant="default"
                      />
                    </S.ClassesTitleSection>
                    <ButtonComponent
                      variant={'outline'}
                      size={'small2'}
                      text={'Definir datas'}
                      fontWeight={'normal'}
                      iconEnd={<UilCalender size={16} />}
                      onClick={() => handleNavigateToSchedule(item.idClass)}
                      data-testId="button-schedule-other-class"
                    />
                  </S.ClassesCardContainer>
                ))}
            </>
          )}
        </S.ContentContainer>

        <ButtonComponent
          variant={'solid'}
          size={'medium-squared'}
          text={'Fazer isso depois'}
          iconEnd={<UilArrowRight size={24} />}
          onClick={actionScheduleLater}
          data-testId="button-schedule-later"
        />
      </S.ModalClassesAvailableContainer>
    </ModalComponentBase>
  )
}
