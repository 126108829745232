import React from 'react'

import { UilArrowDown } from '@iconscout/react-unicons'

import CardCarousel from '../Carrousell/CardCarousel'
import { CustomHtmlProps, OrientationItem, Props } from './StepAccordion.interfaces'
import * as S from './StepAccordion.styles'

const formatHtmlContent = (htmlContent: string) => {
  return htmlContent
    .replace(/color: rgb\(\d+, \d+, \d+\)/g, 'color: black')
    .replace(/font-weight:\s*700/g, 'font-weight: bold')
    .replace(/<p><br><\/p>(\s*<p><br><\/p>)*/g, '<p><br></p>')
    .replace(/<p>\s*<br>\s*<\/p>/g, '')
    .replace(/<strong>/g, '<span style="font-weight: bold; font-size: 12px;">')
    .replace(/<\/strong>/g, '</span>')
    .replace(/<u>/g, '<span style="text-decoration: underline; font-size: 12px;">')
    .replace(/<\/u>/g, '</span>')
    .replace(/<h2([^>]*)>/g, '<h2$1 style="font-size: 18px; font-weight: bold;">')
    .replace(/<p([^>]*)>/g, '<p$1 style="font-size: 18px;">')
    .replace(/<ul([^>]*)>/g, '<ul$1 style="list-style-type: disc; margin-left: 5px;">')
    .replace(/<ol([^>]*)>/g, '<ul$1 style="list-style-type: disc; margin-left: 5px;">')
    .replace(/<li([^>]*)>/g, '<li$1 style="margin-left: 5px;">')
}

const CustomHtml: React.FC<CustomHtmlProps> = ({ htmlContent }) => {
  return <S.AccordionText dangerouslySetInnerHTML={{ __html: formatHtmlContent(htmlContent) }} />
}

export const StepAccordion: React.FC<Props> = ({
  title,
  orientationItems,
  type,
  key,
  accordionRef,
  expaded = true,
  onToggle,
  nonCollapsible = false,
}) => (
  <S.AccordionContainer
    expanded={nonCollapsible || expaded}
    onChange={nonCollapsible ? undefined : onToggle}
    ref={accordionRef}
  >
    {!nonCollapsible && (
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.AccordionTitle type={type}>{title}</S.AccordionTitle>
      </S.AccordionHeader>
    )}
    {nonCollapsible && (
      <S.StepOrientationContainerTitle>
        <S.AccordionTitlenoNCollapsible type={type}>{title}</S.AccordionTitlenoNCollapsible>
      </S.StepOrientationContainerTitle>
    )}

    <S.StepOrientationContainerTitle>
      {orientationItems.map((item: OrientationItem, index) => (
        <React.Fragment key={index}>
          <CustomHtml htmlContent={String(item.txOrientationCode)} />
        </React.Fragment>
      ))}

      {orientationItems?.some((item) => item.lessonActivitySupportReference?.length > 0) && (
        <S.ReferenceContainer>
          <S.AccordionTitle type={type} className="reference">
            Referências de Apoio
          </S.AccordionTitle>
          {orientationItems.map((item, index) =>
            item.lessonActivitySupportReference && item.lessonActivitySupportReference.length > 0 ? (
              <CardCarousel key={`carousel-${index}`} items={item.lessonActivitySupportReference} type={type} />
            ) : null,
          )}
        </S.ReferenceContainer>
      )}
    </S.StepOrientationContainerTitle>
  </S.AccordionContainer>
)
