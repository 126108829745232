/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilFavorite, UilNotes, UilStar, UilThumbsUp } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { useSnackbar } from '~/_context/Snackbar'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useServiceUpdateProjectStatus, useServiceUpdateProjectStatusBatch } from '~/services/Lesson'
import { useServiceUpdateTrailProject } from '~/services/TrailEdit'

import {
  AccordionTextComponent,
  AppBarComponent,
  BackNavigationComponent,
  CardNavigationComponent,
  ContainerComponent,
  DividerComponent,
  ModalActionComponent,
  ModalChangeTimeComponent,
  ModalDocumentationComponent,
  ModalDownloadList,
  ModalPlaneComponent,
  ModalStudentManagementComponent,
  SkeletonComponent,
} from '~/components'
import { HeaderLessonFullComponent } from '~/components/Header/HeaderLesson/HeaderLessonFull'
import InactiveTag from '~/components/ui/InactiveTag/InactiveTag'
import { handleStatusStep, StepsTrackingChipComponent } from '~/components/ui/StepsTracking/StepsTracking'

import { mapClassShift } from '~/validations/mapClassShift'
import { mapCoStage } from '~/validations/mapCoStage'
import { renderStatusText } from '~/validations/renderStatusText'

import { useProjectPageContext } from './context'
import { Students } from './interfaces'
import * as S from './styles'

interface ProjectViewProps {
  isOffline?: boolean
}

export const ProjectView = ({ isOffline }: ProjectViewProps) => {
  const queryClient = useQueryClient()
  const { projectId, idClass } = useParams()
  const {
    isLoading,
    data,
    refetchProjectInfo,
    dataStudents,
    isLoadingStudentsGroup,
    dataStudentsPayload,
    dataDetails,
    statusResponse,
    loadingDataModal,
    modalOpen,
    newStatus,
    newStatusCode,
    statusCode,
    status,
    setStatus,
    showSucessModal,
    showTimeModal,
    showCancelModalLesson,
    isSameUser,
    setShowTimeModal,
    setShowTimeModalEM,
    setShowCancelModalLesson,
    setModalOpen,
    handleChangeStatus,
    handleChangeStatusSheduled,
    handleLeaveSucessModal,
    handleOpenModalSucess,
    refetchStudentsGroup,
    isClassInStatus,
    handleSchedule,
  } = useProjectPageContext()

  const { showSnackbarError } = useSnackbar()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [isSame, setIsSame] = useState<boolean>(false)
  const [showModalAVPE, setShowModalAVPE] = useState(false)
  const [showPlanModal, setShowPlanModal] = useState(false)
  const [isMomentOutdated, setIsMomentOutdated] = useState(false)
  const dataStudentsGroup = dataStudentsPayload?.group

  const updateSubGroupsByMomentStage = () => {
    const subGroupsByMomentStage = dataStudentsPayload?.subGroupsByMomentStage || []

    const missingStudents = dataStudents?.filter(
      (student: { idStudent: number }) =>
        !subGroupsByMomentStage.some((projectMomentStage: { subGroups: any[] }) =>
          projectMomentStage?.subGroups?.some((subGroup) =>
            subGroup?.group.some(
              (groupStudent: { idStudent: number }) => groupStudent?.idStudent === student?.idStudent,
            ),
          ),
        ),
    )

    const getRandomIndex = (length: number) => Math.floor(Math.random() * length)
    const getRandomElement = (array: string | any[]) => array[getRandomIndex(array?.length)]
    missingStudents?.forEach(
      (student: { idUserStudent: number; idStudent: number; idUser: number; txName: string; txImagePath: string }) => {
        const randomProjectMomentStage = getRandomElement(subGroupsByMomentStage)
        const randomSubGroup = getRandomElement(randomProjectMomentStage?.subGroups || [])
        const existingStudent = getRandomElement(randomSubGroup?.group || [])

        randomSubGroup?.group?.push({
          idProjectMomentGroup: existingStudent?.idProjectMomentGroup,
          idUserStudent: student?.idUserStudent,
          idStudent: student?.idStudent,
          inAttendance: false,
          projectGroupAssessment: existingStudent?.projectGroupAssessment || [],
          studentClass: {
            idStudent: student?.idStudent,
            inStatus: true,
            student: {
              idStudent: student?.idStudent,
              idUserStudent: student?.idUserStudent,
              idUser: student?.idUser,
              txName: student?.txName,
              txImagePath: student?.txImagePath || '',
              inDeleted: false,
            },
          },
          nuSubGroup: randomSubGroup?.nuSubGroup,
        })
      },
    )

    const updatedDataStudentsGroup = {
      ...dataStudentsPayload,
      subGroupsByMomentStage,
    }
    return updatedDataStudentsGroup
  }

  const dataUpdated = dataStudentsPayload ? updateSubGroupsByMomentStage() : []

  const [loadingRequest, setLoading] = useState<boolean>(false)

  const titleNuOrder = data?.projectTrackStage?.txTitle
  const downloadListFiles = data?.projectTrackStage?.projectTrackStageFile
  const classBefore = dataDetails?.scheduledProjectMomentByClassAndTrackId?.items

  const dtSchedule = data?.dtSchedule as string
  const txClassName = data?.class?.txName as string
  const txGrade = data?.class?.schoolGrade?.grade?.txGrade

  const classShift = data?.class?.coClassShift
  const coStage = data?.class?.schoolGrade?.grade.coStage

  const nuOrderProjectTrackStages = data?.nuOrderProjectTrackStages
  const nuProjectTrackStages = data?.nuProjectTrackStages

  const txTrackTitle = data?.projectTrackStage?.txTrackTitle
  const idProjectTrack = data?.projectTrackStage?.idProjectTrack
  const inStatus = data?.class?.inStatus

  const txProfessorImagePath = data?.professor?.txImagePath
  const txProfessorName = data?.professor?.txName

  const activityOne = data?.projectMomentStage?.[0]?.idProjectMomentStage
  const activityTwo = data?.projectMomentStage?.[1]?.idProjectMomentStage
  const activityThree = data?.projectMomentStage?.[2]?.idProjectMomentStage
  const projectMomentStage = data?.projectMomentStage

  const idTrack = data?.projectTrackStage?.idProjectTrack

  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [showModalStudentManagement, setShowModalStudentManagement] = useState(false)

  const validateDate = (date: string): boolean => {
    const formattedDate = dayjs(date)
    const currentDate = dayjs()
    const isTodayOrBefore = formattedDate.isBefore(currentDate, 'day') && !formattedDate.isSame(currentDate, 'day')
    return isTodayOrBefore
  }

  const statusContext = (value: string) => {
    switch (value) {
      case 'AUPL':
        return 'AVPE'
      case 'AGUA':
        return 'INIC'
      case 'FINA':
        return 'INIC'
      case 'AGEN':
        return 'INIC'
      case 'AUPE':
        return 'AVPE'
      case 'AVPE':
        return 'FINA'
      case 'PEND':
        return 'AUPL'
      case 'INIC':
        return 'AVPE'
      default:
        return 'Status Inválido'
    }
  }

  const {
    mutate: handleLeaveTrail,
    error: errorDataLesson,
    isLoading: loadingDataLesson,
    data: dataResponseLesson,
  } = useServiceUpdateProjectStatus(
    statusContext(data?.momentStatus?.coMomentStatus as string),
    Number(projectId),
    Number(idClass),
  )

  const {
    mutate: handleLeaveTrailRemark,
    error: errorDataLessonRemark,
    isLoading: loadingDataLessonRemark,
    data: dataResponseLessonRemark,
  } = useServiceUpdateProjectStatus('AUPL', Number(projectId), Number(idClass))

  useEffect(() => {
    if (!loadingDataLessonRemark && errorDataLessonRemark) {
      showSnackbarError(errorDataLessonRemark?.response?.data.message || 'Ocorreu um erro.')
    }
  }, [errorDataLessonRemark, loadingDataLessonRemark, showSnackbarError])

  useEffect(() => {
    if (!loadingDataLessonRemark && dataResponseLessonRemark) {
      queryClient.invalidateQueries(['getProjectInformation']).then()
    }
    // eslint-disable-next-line
  }, [, loadingDataLessonRemark, queryClient, dataResponseLessonRemark])

  const {
    mutate: handleLeaveTrailBatch,
    error: errorDataLessonBatch,
    isLoading: loadingDataLessonBatch,
    data: dataResponseLessonBatch,
  } = useServiceUpdateProjectStatusBatch()

  useEffect(() => {
    if (!loadingDataLesson && errorDataLesson) {
      showSnackbarError(errorDataLesson?.response?.data.message || 'Ocorreu um erro.')
    }
    if (!loadingDataLessonBatch && errorDataLessonBatch) {
      showSnackbarError(errorDataLessonBatch?.response?.data?.message || 'Ocorreu um erro.')
    }
  }, [errorDataLesson, loadingDataLesson, showSnackbarError, errorDataLessonBatch, loadingDataLessonBatch])

  useEffect(() => {
    if (!loadingDataLesson && dataResponseLesson) {
      queryClient.invalidateQueries(['getProjectInformation']).then(() => setOpenModalSucess(true))
    }
    if (!loadingDataLessonBatch && dataResponseLessonBatch) {
      queryClient.invalidateQueries(['getProjectInformation']).then(() => setOpenModalSucess(true))
    }
  }, [dataResponseLesson, loadingDataLesson, queryClient, loadingDataLessonBatch, dataResponseLessonBatch])

  const handleOpenModalAVPE = useCallback(() => {
    setShowModalAVPE(true)
  }, [setShowModalAVPE])

  const handleCloseModalAVPE = useCallback(() => {
    setShowModalAVPE(false)
  }, [setShowModalAVPE])

  const cardRef = useRef<HTMLDivElement>(null)

  const handleConfirmModalAVPE = useCallback(() => {
    handleLeaveTrail()
    setShowModalAVPE(false)
  }, [handleLeaveTrail])

  const handleConfirmPlan = (idMoments: number[]) => {
    if (idMoments.length === 0) {
      handleLeaveTrail()
    } else {
      const status = statusContext(data?.momentStatus?.coMomentStatus as string)
      handleLeaveTrailBatch({
        status,
        idClass,
        idProjectMoments: idMoments,
      })
    }
    setShowPlanModal(false)
  }

  const handleOpenPlane = () => {
    setShowPlanModal(true)
  }

  const handleCancelPlane = () => {
    setShowPlanModal(false)
  }

  const statusChange = () => {
    if (data?.momentStatus?.coMomentStatus === 'AVPE') {
      handleOpenModalAVPE()
    } else if (data?.momentStatus?.coMomentStatus === 'PEND') {
      isMomentOutdated ? setShowCancelModalLesson(true) : handleOpenPlane()
    } else if (data?.momentStatus?.coMomentStatus === 'AUPL') {
      handleOpenPlane()
    } else if (data?.momentStatus?.coMomentStatus === 'AUPE') {
      setShowCancelModalLesson(true)
    } else {
      handleLeaveTrail()
    }
  }

  useEffect(() => {
    if (status === undefined && data?.momentStatus?.coMomentStatus) {
      setStatus(data?.momentStatus?.coMomentStatus)
    }
  }, [data, setStatus, status])

  const {
    mutate: updateTrailProject,
    error: errorData,
    isLoading: loadingData,
    data: dataResponse,
  } = useServiceUpdateTrailProject()

  useEffect(() => {
    if (!loadingData && errorData) {
      setLoading(false)
      showSnackbarError(errorData?.response?.data.message)
    }
  }, [errorData, loadingData, showSnackbarError])

  useEffect(() => {
    if (!loadingData && dataResponse) {
      setLoading(false)
      setModalOpen(true)
      if (status !== 'AUPL' && isSame) {
        handleLeaveTrailRemark()
      }
      refetchProjectInfo().then(() => {
        setOpenModalSucess(true)
        setShowTimeModal(false)
        setShowTimeModalEM(false)
      })
    }
  }, [
    dataResponse,
    handleLeaveTrailRemark,
    handleOpenModalSucess,
    isSame,
    loadingData,
    queryClient,
    refetchProjectInfo,
    setModalOpen,
    setShowCancelModalLesson,
    setShowTimeModal,
    setShowTimeModalEM,
    status,
  ])

  const [, setStudents] = useState<Students[]>([])

  useEffect(() => {
    if (data && dataStudentsGroup) {
      const newStudents: Students[] = []

      const projects = data?.projectMomentStage as any[]

      for (const project of projects) {
        const index = projects?.findIndex((i) => i.idProjectMomentStage === project.idProjectMomentStage)
        for (const moment of dataStudentsGroup) {
          if (index === 0 && moment.idProjectMomentStage === project.idProjectMomentStage) {
            newStudents.push({
              idProjectMomentStage: project?.idProjectMomentStage,
              idMomentGroup: moment?.idProjectMomentGroup,
              idStudent: moment?.studentClass?.student?.idStudent,
              txImagePath: moment?.studentClass?.student?.txImagePath,
              txName: moment?.studentClass?.student?.txName,
              step: moment?.inAttendance ? 'one' : 'absent',
              activities: {
                activityOne: activityOne ?? 0,
                activityTwo: activityTwo ?? 0,
                activityThree: activityThree ?? 0,
              },
            })
          } else if (index === 1 && moment.idProjectMomentStage === project.idProjectMomentStage) {
            newStudents.push({
              idProjectMomentStage: project?.idProjectMomentStage,
              idMomentGroup: moment?.idProjectMomentGroup,
              idStudent: moment?.studentClass?.student?.idStudent,
              txImagePath: moment?.studentClass?.student?.txImagePath,
              txName: moment?.studentClass?.student?.txName,
              step: moment?.inAttendance ? 'two' : 'absent',
              activities: {
                activityOne: activityOne ?? 0,
                activityTwo: activityTwo ?? 0,
                activityThree: activityThree ?? 0,
              },
            })
          } else if (index === 2 && moment.idProjectMomentStage === project.idProjectMomentStage) {
            newStudents.push({
              idProjectMomentStage: project?.idProjectMomentStage,
              idMomentGroup: moment?.idProjectMomentGroup,
              idStudent: moment?.studentClass?.student?.idStudent,
              txImagePath: moment?.studentClass?.student?.txImagePath,
              txName: moment?.studentClass?.student?.txName,
              step: moment?.inAttendance ? 'three' : 'absent',
              activities: {
                activityOne: activityOne ?? 0,
                activityTwo: activityTwo ?? 0,
                activityThree: activityThree ?? 0,
              },
            })
          }
        }
      }

      setStudents(newStudents)
    }
  }, [activityOne, activityThree, activityTwo, data, dataStudentsGroup])

  const handleCloseModalTime = async () => {
    setShowTimeModal(false)
    setShowTimeModalEM(false)
  }

  const handleCloseModalSucess = useCallback(() => {
    setOpenModalSucess(false)
    setShowTimeModal(false)
    setShowTimeModalEM(false)
    refetchProjectInfo().then()
    // eslint-disable-next-line
  }, [setShowTimeModal, setShowTimeModalEM])

  useEffect(() => {
    if (!isLoading && data && validateDate(dtSchedule)) {
      if (['AUPL', 'PEND'].includes(data?.momentStatus?.coMomentStatus as string) && !modalOpen) {
        setIsMomentOutdated(true)
      }
    }
  }, [data, dtSchedule, isLoading, modalOpen, setIsMomentOutdated, showCancelModalLesson, status])

  const titleByNuOrder = (nuOrder: number) => {
    switch (nuOrder) {
      case 1:
        return 'Contextualização e Chuva de Ideias'
      case 2:
        return 'Ideação e Planejamento'
      case 3:
        return 'Execução do Planejamento'
      case 4:
        return 'Partilha e Escuta Coletiva'
    }
  }
  const handleCloseModalStudentManagement = () => setShowModalStudentManagement(false)

  const handleOpenModalStudentManagement = () => {
    if (dataStudentsGroup && !isLoadingStudentsGroup) {
      setShowModalStudentManagement(true)
    }
  }

  const [isScrolling, setIsScrolling] = useState(false)
  const sentinelRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScrollCheck = () => {
      return document.documentElement.scrollHeight > window.innerHeight
    }

    if (handleScrollCheck()) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsScrolling(!entry.isIntersecting)
        },
        {
          root: null,
          threshold: 0.1,
        },
      )

      if (sentinelRef.current) {
        observer.observe(sentinelRef.current)
      }

      return () => {
        if (sentinelRef.current) {
          observer.unobserve(sentinelRef.current)
        }
      }
    }
    localStorage.removeItem('@LEKTO:nuOrder')
  }, [])

  return (
    <S.ContainerScroll className="styled-scroll">
      <AppBarComponent />

      {isLoading && (
        <ContainerComponent>
          <S.ContentSkeleton>
            <SkeletonComponent width={'80%'} height={60} />
            <SkeletonComponent width={200} height={60} />
            <SkeletonComponent width={100} height={60} />
            <SkeletonComponent width={'100%'} height={60} variant={'text'} />

            <SkeletonComponent width={'100%'} height={200} variant={'text'} />

            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
          </S.ContentSkeleton>
        </ContainerComponent>
      )}

      {data && (
        <ContainerComponent>
          <S.ContentHeader>
            <>
              {!isScrolling ? (
                <S.ContentBack isMobile={Boolean(isMobile)}>
                  <BackNavigationComponent path={`/home?coMomentStatus=${data?.momentStatus?.coMomentStatus}`} />
                  <S.WrapperActionHeader>
                    <ModalDownloadList files={Array.isArray(downloadListFiles) ? downloadListFiles : []} />
                    <S.WrapperStudents>
                      {isLoading ? (
                        <SkeletonComponent height={20} width={160} variant="rounded" />
                      ) : (
                        <>
                          {!isOffline ? (
                            <>
                              <S.ContainerTrackClass>
                                <S.ClassSpanInfo>{mapClassShift(classShift || '')}</S.ClassSpanInfo>
                                <S.ClassSpanInfo>{mapCoStage(coStage ?? '')}</S.ClassSpanInfo>
                                <S.ClassSpanInfo>{txGrade}</S.ClassSpanInfo>
                                <Tooltip title={txClassName ? txClassName : ''}>
                                  <S.ClassSpanInfo>{txClassName}</S.ClassSpanInfo>
                                </Tooltip>
                                {inStatus === false && <InactiveTag />}
                              </S.ContainerTrackClass>
                            </>
                          ) : (
                            <S.WrapperClass>{`${txGrade} - ${txClassName}`}</S.WrapperClass>
                          )}
                        </>
                      )}
                    </S.WrapperStudents>
                  </S.WrapperActionHeader>
                </S.ContentBack>
              ) : (
                <S.ContentBackSmall>
                  <BackNavigationComponent path={`/home?coMomentStatus=${data?.momentStatus?.coMomentStatus}`} />
                  <S.Title variant="h1">{isLoading ? <SkeletonComponent width={400} /> : titleNuOrder}</S.Title>
                </S.ContentBackSmall>
              )}
            </>
            {dataDetails && (
              <S.HeaderRef>
                <HeaderLessonFullComponent
                  setShowTimeModal={handleSchedule}
                  loading={isLoading}
                  isSameUser={isSameUser}
                  loadingChangeStatus={loadingDataLesson}
                  classBefore={classBefore}
                  projectId={Number(projectId)}
                  title={titleNuOrder}
                  statusCode={data?.momentStatus?.coMomentStatus as string}
                  statusText={renderStatusText(data?.momentStatus?.coMomentStatus as string)}
                  dtSchedule={dtSchedule}
                  components={projectMomentStage?.[0]?.projectStage?.project?.projectComponent}
                  actionStatus={() => statusChange()}
                  avatas={dataStudents}
                  dateHour={dayjs(dtSchedule).format('DD/MM/YYYY')}
                  lessonYear={`${txGrade} - ${txClassName}`}
                  actionManageStudents={() => handleOpenModalStudentManagement()}
                  coStage={coStage}
                  isClassInStatus={isClassInStatus}
                  classShift={classShift}
                  professorImagePath={txProfessorImagePath}
                  professorName={txProfessorName}
                  nuLessonTrackGroups={nuProjectTrackStages}
                  nuOrderLessonTrackGroup={nuOrderProjectTrackStages}
                  loadingStudents={isLoadingStudentsGroup}
                  isMobile={isMobile}
                  isSameProject={true}
                  isScrolling={isScrolling}
                  txTrackTitle={txTrackTitle}
                  idMomentTrack={idProjectTrack}
                  idClass={idClass}
                />
              </S.HeaderRef>
            )}
          </S.ContentHeader>

          <DividerComponent />

          <S.Content>
            {nuProjectTrackStages !== 1 && data?.projectTrackStage?.txGuidanceCode?.length > 0 && (
              <>
                <AccordionTextComponent
                  paragraph={
                    data?.projectTrackStage?.txGuidanceCode?.length > 0
                      ? (data?.projectTrackStage?.txGuidanceCode as string)
                      : isLoading
                      ? 'Carregando...'
                      : 'Sem descrição para a etapa.'
                  }
                  limit={260}
                  typePlus={'bottom'}
                />
                <S.ContentDescription isMobile={Boolean(isMobile)} isScrolling={isScrolling}></S.ContentDescription>
                <div ref={sentinelRef} style={{ height: '1px' }}></div>
                <DividerComponent />
              </>
            )}

            <S.ContentPlanning>
              {handleStatusStep(statusResponse) === 'Planning' ? (
                <StepsTrackingChipComponent
                  stepNumber={1}
                  text={'Planejamento e Ação'}
                  variant={'onStep'}
                  status={statusResponse}
                />
              ) : (
                <StepsTrackingChipComponent
                  stepNumber={1}
                  text={'Planejamento e Ação'}
                  variant={'checked'}
                  status={statusResponse}
                />
              )}
              <CardNavigationComponent
                label="Orientações da Aula"
                pathNavigation={`/class/${idClass}/project/${projectId}/planning`}
                testId="orientations-section-navigation"
              />
            </S.ContentPlanning>

            <DividerComponent />
            <div ref={sentinelRef} style={{ height: '1px' }}></div>
            <S.ContentNavigation>
              {handleStatusStep(statusResponse) === 'Assessment' ? (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={'onStep'}
                  status={statusResponse}
                />
              ) : (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={
                    handleStatusStep(statusResponse) === 'Action' || handleStatusStep(statusResponse) === 'Planning'
                      ? 'disable'
                      : 'checked'
                  }
                  status={statusResponse}
                />
              )}
              <CardNavigationComponent
                icon={<UilNotes />}
                label="Documentações"
                disabled={!['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(statusResponse)}
                pathNavigation={`/class/${idClass}/project-documentations/${projectId}`}
                testId="document-section-navigation"
              />

              <S.CardRef ref={cardRef}>
                <CardNavigationComponent
                  icon={<UilFavorite />}
                  label="Avaliações"
                  disabled={!['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(statusResponse)}
                  pathNavigation={`/class/${idClass}/project-assessment/${projectId}`}
                  testId="assessment-section-navigation"
                />
              </S.CardRef>
            </S.ContentNavigation>
          </S.Content>
          {data?.momentStatus?.coMomentStatus === 'PEND' ? (
            <ModalPlaneComponent
              actionCofirm={handleConfirmPlan}
              icon={<UilThumbsUp size={24} color="#29CC5F" />}
              titleLabel="Parabéns por planejar mais uma aula!"
              open={showPlanModal}
              idProject={Number(data?.projectTrackStage?.idProjectTrackStage)}
              description={'Tem certeza que deseja confirmar o planejamento dessa aula?'}
              actionCloseModal={handleCancelPlane}
              confirmCancelText="Confirmar Planejamento"
            />
          ) : (
            <ModalPlaneComponent
              actionCofirm={handleConfirmPlan}
              icon={<UilThumbsUp size={24} color="#29CC5F" />}
              titleLabel="Parabéns por realizar mais uma aula!"
              open={showPlanModal}
              description={'Tem certeza que deseja confirmar a realização dessa aula?'}
              actionCloseModal={handleCancelPlane}
              confirmCancelText="Confirmar realização"
            />
          )}

          <ModalActionComponent
            actionCofirm={handleConfirmModalAVPE}
            icon={<UilStar size={20} color="#0494fc" />}
            title={
              'Após confirmar a avaliação, não será mais possível fazer alterações e esta aula irá constar no histórico individual dos alunos.'
            }
            description="Tem certeza que deseja confimar a avaliação?"
            open={showModalAVPE}
            actionCloseModal={handleCloseModalAVPE}
            confirmCancelText="Confirmar Avaliação"
            returnBtnText="Revisar Avaliação"
            infoWidth={700}
            isFullModal={true}
            isConfirm={true}
            idClass={idClass as number}
            IdMoment={Number(projectId)}
          />

          <ModalActionComponent
            open={showSucessModal}
            title="Avaliação confirmada com sucesso!"
            actionCloseModal={() => handleLeaveSucessModal()}
            infoWidth={700}
            isFullModal={false}
          />

          {isSameUser && !showTimeModal && (
            <ModalChangeTimeComponent
              open={showCancelModalLesson}
              actionScheduleModal={handleSchedule}
              isLoadingRequest={loadingDataLesson}
              actionCofirm={() => handleChangeStatusSheduled('AVPE')}
              dateHour={dtSchedule}
              statusCode={newStatusCode ? statusCode : (data?.momentStatus?.coMomentStatus as string)}
              statusText={newStatus ? renderStatusText(newStatus) : (data?.momentStatus?.txMomentStatus as string)}
              actionCloseModal={() => setShowCancelModalLesson(false)}
              loadingConfirm={loadingDataModal}
              lessonYear={txClassName}
              txGrade={txGrade}
              titleLabel={titleByNuOrder(data?.projectTrackStage?.nuOrder)}
              description="Atualização de Horários"
              classShift={classShift}
              coStage={coStage}
            />
          )}

          <ModalActionComponent
            title={`Aula confirmada com sucesso!`}
            open={openModalSucess}
            actionCloseModal={handleCloseModalSucess}
            infoWidth={700}
            isFullModal={false}
          />

          {dataStudentsGroup && (
            <ModalStudentManagementComponent
              openModal={showModalStudentManagement}
              classID={Number(data?.class?.idClass)}
              actionCloseModal={handleCloseModalStudentManagement}
              dataClass={data}
              idLessonMoment={Number(projectId)}
              type={'F2'}
              dataStudentsGroup={dataUpdated}
              refetchStudents={refetchStudentsGroup}
            />
          )}

          <>
            {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(data?.momentStatus?.coMomentStatus as string) && isSameUser && (
              <ModalDocumentationComponent idProject={data?.idProjectMoment} variant="floater" />
            )}
          </>
        </ContainerComponent>
      )}
    </S.ContainerScroll>
  )
}
