import { useState } from 'react'

import { DropdownSkillComponent, IconComponent } from '~/components'

import * as S from './VariableEvidences.styles'

interface IVariableEvidenceProps {
  isOpen: boolean
  onToggle: () => void
  activity: {
    idLessonMomentActivity: number
    txTitle: string
    categoryGrade: {
      category: {
        txName: string
        txImagePath: string
      }
    }
    category?: {
      txName: string
      txImagePath: string
    }
  }
  studentsGroup: any
  variableSkill: {
    evidence: {
      txName: string
      ability: {
        txName: string
        txImagePath: string
        competence: {
          txName: string
          txImagePath: string
        }
      }
    }
  }
  index?: number
}

interface IVariableEvidenceComponentProps {
  steps: Array<any>
}

export const VariableEvidence = ({ variableSkill, activity, isOpen, onToggle, index }: IVariableEvidenceProps) => {
  return (
    <S.ContainerEvidence data-testid={`VariableSkills${index}`}>
      <S.TitleEvidence>
        {(activity?.categoryGrade?.category?.txImagePath || activity?.category?.txImagePath) && (
          <IconComponent
            color="card"
            fontWeight="normal"
            code={activity?.categoryGrade?.category?.txImagePath || activity?.category?.txImagePath}
            size="xsmall"
          />
        )}
        <span>
          {activity?.categoryGrade?.category?.txName || activity?.category?.txName
            ? `${activity?.categoryGrade?.category?.txName || activity?.category?.txName} - `
            : ''}
          {activity?.txTitle}
        </span>
      </S.TitleEvidence>
      <DropdownSkillComponent
        isOpen={isOpen}
        iconSkill={variableSkill?.evidence?.ability?.txImagePath}
        iconCompetency={variableSkill?.evidence?.ability?.competence?.txImagePath}
        onToggle={onToggle}
        txSkillName={variableSkill?.evidence?.ability?.txName}
        txSkillDescription={variableSkill?.evidence?.txName}
        txBncc=""
        txCompetency={variableSkill?.evidence?.ability?.competence?.txName}
      />
    </S.ContainerEvidence>
  )
}

export const VariableEvidencesComponent = ({ steps }: IVariableEvidenceComponentProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index)
  }

  return (
    <S.Container>
      <S.Title>Habilidades Variáveis</S.Title>
      {steps
        .filter((activity: any) =>
          activity?.lessonActivityEvidence.find((evidence: any) => evidence?.coEvidenceType === 'VAR'),
        )
        ?.map((item: any, index: number) => (
          <VariableEvidence
            index={index}
            key={item.idLessonActivity}
            activity={item}
            isOpen={index === openDropdownIndex}
            onToggle={() => handleDropdownToggle(index)}
            studentsGroup={item.studentsGroup}
            variableSkill={
              item.lessonActivityEvidence?.find((item: any) => item?.coEvidenceType === 'VAR')?.evidenceGrade
            }
          />
        ))}
    </S.Container>
  )
}
