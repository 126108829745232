import { useState } from 'react'

import { truncateText } from '~/validations/truncateText'

import * as S from './CardDescription.styles'
export const CardDescription = ({ description, isAboutClass = false }) => {
  const [verTexto, setVerTexto] = useState(false)

  const handleVerTexto = () => {
    setVerTexto(!verTexto)
  }

  const truncated = truncateText(description, 500)

  return (
    <S.CardDescription>
      <S.Description>
        {isAboutClass ? <span>Sobre a aula: </span> : <strong>Sobre a trilha: </strong>}
        {verTexto ? description : truncated}

        {description?.length > 500 && (
          <S.BtnAction onClick={handleVerTexto}>{verTexto ? ' Ver menos' : ' Ver mais'}</S.BtnAction>
        )}
      </S.Description>
    </S.CardDescription>
  )
}
